.welcome-wrapper{
    max-width: 95%;
    margin: 0 auto;
    padding: 35px 0;
}
.border-primary{
  border-radius: 4px;
}
.welcome-image-container{
    display: -webkit-box;     
    display: -moz-box;         
    display: -ms-flexbox;      
    display: -webkit-flex;    
    display: flex;    
    -webkit-justify-content: space-between;  
    justify-content: space-between;     
}

.margin-top-1-33em{
margin-top: 1.33em;
}
.welcome-button{
    /* border-radius: unset; */
    margin-left: 1em;
    width: 120px;
}
.minus-10-left{
 margin-left: -10px;
}
.welcome-white-bg{
  background-color: #fff;
   padding:20px;
}