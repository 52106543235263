.custom-table-styles span {
  white-space: nowrap ;
}

.custom-table-styles table {
  margin: 20px !important;
  width: 98% !important;
}

.custom-table-styles td,
th {
  padding: 0px 16px !important;
  height: 45px;
  white-space: nowrap !important;
}

.custom-table-styles tr:hover {
  background-color: #eaeef1;
}
.custom-table-styles button {
  width: unset !important;
}
.custom-table-styles th {
  background-color: #eaeef1 !important;
}

.custom-table-styles th span div {
  font-weight: 600;
  color: #00000091;
}

table tr td:nth-child(1) a {
  color: #3c73a8 !important;
  text-decoration: none;
}

.rating {
  padding: 5px 20px;
  width: 120px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  border-radius: 5px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.header_text {
  font-size: 20px;
  font-weight: bold;
}

.custom-table-styles tfoot tr {
  text-align: -webkit-right;
}
.custom-table-styles tfoot tr td {
  float: unset !important;
}
.text-align-right {
  text-align: right;
}
.btn-box {
  display: flex;
  justify-content: space-between;
}
.text-align-right button {
  margin: 10px;
  width: 150px;
}
.logout {
  margin: 10px;
}
.logout button {
  width: 150px !important;
}
.pointer {
  cursor: pointer;
}
.MuiDialog-paperWidthSm {
  min-width: 400px;
}

.details-tab{
  width: 100px;
  font-weight: bold;
  display: block;
  line-height: 16px;
  padding: 2px;
  white-space: unset !important;
}