html,
body {
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.background-image {
  background-image: url("./assets/_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
}
.primary-color{
  /*color: #76B82A;*/
  color: #76B82A;
}
.color-red{
  color: red;
}
.blue{
  color: blue !important;
}
.display-inline-block{
  display: inline-block;
}
.font-weight-bold{
  font-weight: bold;
}
.font-size-21{
  font-size: 21px;
}
.font-size-16{
  font-size: 16px;
}