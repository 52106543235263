.absolute-center {
    text-align: center;
    max-width: 35%;
    margin:0 auto;
    padding: 50px 0;

}
.text-align-left{
    text-align: left !important;
}
.white-bg {
    background-color: #fff;
    padding: 0px 20px 20px;
}

.center-container {
    position: relative;
}

.margin-top {
    margin-top: 20px;
}

.margin-left {
    margin-left: 30px;
}

@media only screen and (min-device-width: 200px) and (max-device-width: 430px) {
    .absolute-center {
        max-width: 95%;
    }
}

@media only screen and (min-device-width: 431px) and (max-device-width: 568px) {
    .absolute-center {
        max-width: 80%;
    }
}

@media only screen and (min-device-width: 569px) and (max-device-width: 767px) {
    .absolute-center {
        max-width: 60%;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 812px) {
    .absolute-center {
        max-width: 60%;
    }
}

@media only screen and (min-device-width: 813px) and (max-device-width: 1024x) {
    .absolute-center {
        max-width: 47%;
    }
}
