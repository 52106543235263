html, body {
    min-height: 100%;
}
body, div, .form, input, select, p {
    padding: 0;
    margin: 0;
    outline: none;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    color: rgb(42, 43, 45);
    line-height: 22px;
}
h1 {
    position: absolute;
    margin: 0;
    font-size: 32px;
    color: #fff;
    z-index: 2;
}
h2 {
    font-weight: 400;
}
.testbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    padding: 20px;
}
.form {
    width: 100%;
    padding: 20px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 0 20px 0 #76B82A;
}
.banner {
    position: relative;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.banner_image {
    width: 100%;
    height: auto;
    object-fit: fill;
}

/*@media (min-width: 1300px) {
    .banner {
        background-color: #d9d9d9;
    }

    .banner_image {
        width: 80%;
    }
}*/

.banner::after {
    content: "";
    /* background-color: rgba(0, 0, 0, 0.4); */
    position: absolute;
    width: 100%;
    height: 100%;
}
input, select {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
input {
    width: calc(100% - 10px);
    padding: 5px;
}
select {
    width: 100%;
    padding: 7px 0;
    background: transparent;
}
.item:hover p, .item:hover i, .question:hover p, .question label:hover, input:hover::placeholder, a {
    color: #76B82A;
}
.item input:hover, .item select:hover {
    border: 1px solid transparent;
    box-shadow: 0 0 6px 0 #76B82A;
    color: #76B82A;
}
.item {
    position: relative;
    margin: 10px 0;
}
input[type="date"]::-webkit-inner-spin-button {
    display: none;
}
.item i, input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    font-size: 20px;
    color: #a9a9a9;
}
.item i {
    right: 2%;
    top: 30px;
    z-index: 1;
}
[type="date"]::-webkit-calendar-picker-indicator {
    right: 1%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}
input[type=checkbox]  {
    display: none;
}
label.check {
    position: relative;
    display: inline-block;
    margin: 5px 20px 10px 0;
    cursor: pointer;
}
.question span {
    margin-left: 30px;
}
span.required {
    margin-left: 0;
    color: red;
}
label.check:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #76B82A;
}
input[type=checkbox]:checked + .check:before {
    background: #76B82A;
}
label.check:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 4px;
    width: 8px;
    height: 4px;
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    opacity: 0;
}
input[type=checkbox]:checked + label:after {
    opacity: 1;
}
.btn-block {
    margin-top: 10px;
    text-align: center;
}
button, .view-pdf {
    width: 150px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #76B82A;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}
button:hover {
    background: #76B82A;
}
.width-save
{
    width: 270px;
}
@media (min-width: 568px) {
    .name-item, .city-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .name-item input, .city-item input {
        width: calc(50% - 20px);
    }
    .city-item select {
        width: calc(50% - 8px);
    }
}

.two-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ml-12 {
    margin-left: 12px;
}

.line-height-1-2 {
    line-height: 1.14;
}

.text-decoration-none {
    text-decoration: none;
}

button:disabled,
button[disabled]{
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

select:disabled,
select[disabled]{
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
