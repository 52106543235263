.form_heading {
  font-weight: bold;
  color: black;
  margin-top: 0;
}

.form-container {
  max-width: 65%;
  margin: 0 auto;
}

.form-input {
  border-radius: 0;
  border: none;
  padding: 0;
  padding-bottom: 0px;
  padding-top: 5px;
}

.input-border {
  border-bottom: 1px solid #80808052 !important;
}

.form-input::placeholder {
  color: #80808052;
}

.form-input-submit {
  background: #76B82A;
  color: white;
  border: none;
  cursor: pointer;
  width: calc(100% - 10px);
  padding: 8px;
  margin-top: 5px;
}

.register-text {
  margin-top: 25px;
  display: inline-block;
  font-size: 12px;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.error {
  color: red;
}

.margin-top-none {
  margin-top: 0px;
}
