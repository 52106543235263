*,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

h3 {
  font-size: 18px;
}

p {
  font-size: 12px;
}

.text-color {
  color: #6f6f6f;
}

.font-bold {
  font-weight: 500;
}

.mk-btn p {
  margin-bottom: 2px;
}

.mk-btn .red-btn {
  background-color: #ff0000;
}

.mk-btn .green-btn {
  background-color: #92d050;
}

.mk-btn .green-btn,
.mk-btn .red-btn {
  color: #fff;
  padding: 7px;
  width: 100px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
